import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';

// all css import
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


// all components and page import
import Header from './components/header/header';
import Home from './pages/home';
import Footer from './components/footer';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <Home />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
