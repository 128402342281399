import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

import Home from './../pages/home';
import About from './../pages/about';
import Testimonial from './../pages/testimonial';

export default function CustFooter() {
    return (
        <>
            <footer className="pt-5 pb-4">
                <Container >
                    <Row>
                        <Col md={6}>
                            <h3 className="font-16 mb-4">Connect with us: 1800-11-6869</h3>
                            <ListGroup horizontal as="ul" className='mb-5'>
                                <ListGroup.Item as="li" className='ps-0'><img src='./images/visa.png' alt="Visa" /></ListGroup.Item>
                                <ListGroup.Item as="li" className='ps-0'><img src='./images/mastercard.png' alt="Master" /></ListGroup.Item>
                                <ListGroup.Item as="li" className='ps-0'><img src='./images/googlepay.png' alt="gpay" /></ListGroup.Item>
                            </ListGroup>
                            <p>&copy; Indianfootages. All rights reserved.</p>
                        </Col>
                        <Col md={2} className='border-right'>
                            <h3 className='font-20 normal mb-4'>Company Info</h3>
                            <Router>
                                <ListGroup as="ul">
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/">Home</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/about">About</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/testimonial">Testimonial</Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Router>
                        </Col>

                        <Col md={2} className='border-right px-4'>
                            <h3 className='font-20 normal mb-4'>Learn more</h3>
                            <Router>
                                <ListGroup as="ul">
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/pricing">Pricing</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/licensing">Licensing</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/tou">Terms of Use</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/privacy">Privacy Policy</Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Router>
                        </Col>
                        
                        <Col md={2} className='ps-4'>
                            <h3 className='font-20 normal mb-4'>Need Help</h3>
                            <Router>
                                <ListGroup as="ul">
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/contact">Contact Us</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/faq">FAQ</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className='px-0'>
                                        <Link to="/technical">Technical</Link>
                                    </ListGroup.Item>
                                </ListGroup>
                                <Routes>
                                    <Route path="./../pages/home" component={Home} />
                                    <Route path="./../pages/about" component={About} />
                                    <Route path="./../pages/testimonial" component={Testimonial} />
                                </Routes>
                            </Router>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}