import { Nav, Button } from 'react-bootstrap';

export default function cartNav() {
    return (
        <Nav>
            <Nav.Item>
                <Button variant="nav-link" className='font-14'>Contact Us</Button>
            </Nav.Item>
            <Nav.Item>
                <Button variant="nav-link" className='font-14'>Pricing</Button>
            </Nav.Item>
            <Nav.Item>
                <Button variant="nav-link" className='font-14 btn-join'>Join Now</Button>
            </Nav.Item>
            <Nav.Item>
                <Button variant="nav-link" className='font-14'>Login</Button>
            </Nav.Item>
            <Nav.Item>
                <Button variant="nav-link" >
                    <span className="material-symbols-outlined">
                        shopping_cart
                    </span>
                </Button>
            </Nav.Item>
        </Nav>
    );
}