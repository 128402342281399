import logo from '../../logo.svg';
import CustNavOffcanvas from './navbar';
import CartNav from './cart_nav';
import { Container, Row, Col } from 'react-bootstrap';

export default function Header() {
    return (
        <>
            <header >
                <Container fluid>
                    <Row className='d-flex align-items-center'>
                        <Col>
                            <CustNavOffcanvas />
                        </Col>
                        <Col>
                            <img src={logo} alt='' className='img-fluid' />
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <CartNav />
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    );
}