import React, { useState } from "react";
import { Offcanvas, Nav, Button, Col, Row } from "react-bootstrap";

export default function OffcanvasMenu() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Row className='d-flex align-items-center px-3'>
                <Col className='col-auto p-0'>
                    <Button onClick={handleShow} className='border-0 d-flex align-items-center px-2 btn-menu'>
                        <span className="material-symbols-outlined font-40">
                            menu
                        </span>
                    </Button>
                </Col>
                <Col className='col-auto p-0 bold ms-2'>Browse Category</Col>
            </Row>


            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Browse Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link href="/home">Active</Nav.Link>
                        <Nav.Link eventKey="link-1">Link</Nav.Link>
                        <Nav.Link eventKey="link-2">Link</Nav.Link>
                        <Nav.Link eventKey="disabled" disabled>
                            Disabled
                        </Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}