import { Container } from "react-bootstrap";

export default function About() {
    return (
        <>
            <Container>
                <h1>About us</h1>
            </Container>
        </>
    )
}