import { Form, Container, Row, Col, Button } from 'react-bootstrap';

export default function Search() {
    return (
        <Container>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className='position-relative text-center'>
                    <Form>
                        <Form.Group className="mb-3" controlId="search">
                            <Button className='btn-search position-absolute border-0'>
                                <span class="material-symbols-outlined">
                                    search
                                </span>
                            </Button>
                            <Form.Control type="search" placeholder="Search here" className='search text-center' />
                            <Form.Label className='font-32 search-label mt-4'>Democratising Indian Footages</Form.Label>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}