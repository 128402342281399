import { Container, Button, ListGroup } from "react-bootstrap";

export default function TagCloud() {
    return (
        <Container fluid className="text-center py-5 popular-search">
            <h2 className='font-32 semi-bold mb-5'>Popular Searches</h2>
            <ListGroup as="ul" className="list-unstyled list-horizontal justify-content-center align-items-center border-0 flex-wrap" horizontal>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Family</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Festival</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Business</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Jewellery</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Craft</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Kitchen</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Medical</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Fashion</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Beauty</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Education</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Business</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Education</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Urban</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Health</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Student</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Rural</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Food</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Baby</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Computer</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Technology</Button>
                </ListGroup.Item>
                <ListGroup.Item as="li" className='border-0 p-0 px-2 mb-3'>
                    <Button className='btn-capsuel px-4 border-0'>Yoga</Button>
                </ListGroup.Item>
            </ListGroup>
        </Container>
    )
}