import MantleFile from './../components/mantle_home';
import MasonryFile from './../components/masonry';
import TagCloud from '../components/keyword';

export default function Home() {
    return (
        <>
            <main>
                <MantleFile />
                <MasonryFile />
                <TagCloud/>
            </main>
        </>
    )
}