import { Container, Row, Col } from 'react-bootstrap';
import Search from './search';

export default function Mantle() {
    return (
        <>
            <Container fluid className='p-0'>
                <Row className='mx-0 position-absolute search-container w-100 h-100 d-flex align-items-center'>
                    <Col>
                        <Search />
                    </Col>
                </Row>

                <Row className='mx-0'>
                    <Col className='p-0'>
                        <video className='img-fluid w-100' autoPlay loop muted >
                            <source src='./video/demo.mp4' type='video/mp4' />
                        </video>
                    </Col>
                </Row>
            </Container>
        </>
    );
}