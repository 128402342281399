import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Container } from "react-bootstrap";
//import { Cloudinary } from 'cloudinary';

const dataMasonary = [
    //    { "poster": "https://images.pexels.com/videos/7235417/adult-animal-baby-bunny-7235417.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    //     "video": "https://player.vimeo.com/external/546448320.sd.mp4?s=d3f7535e8d8361d204c5801d9176886bd4a79574&profile_id=165&oauth2_token_id=57447761"
    // },

    // {
    //     "poster": "https://images.pexels.com/videos/7235417/adult-animal-baby-bunny-7235417.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    //     "video": "https://player.vimeo.com/external/655305103.sd.mp4?s=f5fdd3d6b7914f6ea5da4168786a9557e2ad67e7&profile_id=164&oauth_token_id=57447761"
    // }
    "https://player.vimeo.com/external/546448320.sd.mp4?s=d3f7535e8d8361d204c5801d9176886bd4a79574&profile_id=165&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/655305103.sd.mp4?s=f5fdd3d6b7914f6ea5da4168786a9557e2ad67e7&profile_id=164&oauth_token_id=57447761",
    "https://player.vimeo.com/external/488052191.sd.mp4?s=d5e88d65ca1fb79dd32c2656c606ed62d8fd4335&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/progressive_redirect/playback/684472013/rendition/360p?loc=external&oauth2_token_id=57447761&signature=ec19ec2a5caff03aff08d9697d56e3cb227ab471922ddc89191ea46734a92526",
    "https://player.vimeo.com/external/541532737.hd.mp4?s=098bd6e9dc3a8d205d63704737b92d59d464105b&profile_id=174&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/556568411.sd.mp4?s=daa3058e70032bb139eb6bf548bf0c3e2c5c8f11&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/556623896.sd.mp4?s=21e182478e48ff679d131fb30e47feb12a9f5c71&profile_id=165&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/519943720.sd.mp4?s=d9609b546c57dac56dd27752dc62c9a003556042&profile_id=165&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/537550621.sd.mp4?s=d01b441dbace4d02fb2b3c373ea1d226dd8b68ba&profile_id=165&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/527275561.sd.mp4?s=e23b4455249edf7672156bb17ce9a6e0ebeff7ba&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/438908787.sd.mp4?s=f58092a912b23cc4a2846f63bcbbe649dd620431&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/527798425.sd.mp4?s=fde68331a7cf821a442cb0ceb5e7bca243418813&profile_id=165&oauth2_token_id=57447761"
]

class CustMasonry extends React.Component {
    render() {
        return (
            <>
                <Container className='masonry-text-container'>
                    <h1 className='font-32 text-center py-3 mb-0'>Welcome to Indian Footages</h1>
                    <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</p>
                </Container>

                <Container fluid className='masonry p-3'>
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                        <Masonry>
                            {dataMasonary.map((image, i) => (
                                <video className='p-2 img-fluid w-100' autoPlay loop muted>
                                    <source src={image} type='video/mp4' />
                                </video>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </Container>
            </>
        )
    }
}

export default CustMasonry;